.card-produk {
  overflow: hidden;
  /* display: grid; */
  border-radius: 10px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  padding-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  border: 2px solid #e5e2e2; /* Border */
  width: 150px;
  height: 250px;
  margin-bottom: 10px;
}
.gambar-produk {
  display: grid;
  width: 125px;
  height: 125px;
  /* padding: 10px; */
  border-radius: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 10px;
}
.title-produk {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif; /* Font family for consistency */
  font-weight: 600;
  color: #333;
  height: 47px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  margin-left: 10px;
  font-family: "Roboto", sans-serif;
}
.harga-produk {
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
  color: #6a11cb;
  font-family: "Roboto", sans-serif;
}
/* Mobile */
@media only screen and (max-width: 400px) {
  .card-produk {
    overflow: hidden;
    /* display: grid; */
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    padding-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    border: 2px solid #e5e2e2; /* Border */
    width: 150px;
    height: 250px;
    margin-bottom: 10px;
  }
  .gambar-produk {
    display: grid;
    width: 125px;
    height: 125px;
    /* padding: 10px; */
    border-radius: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .title-produk {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif; /* Font family for consistency */
    font-weight: 600;
    color: #333;
    height: 47px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    margin-left: 10px;
    font-family: "Roboto", sans-serif;
  }
  .harga-produk {
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
    color: #6a11cb;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
  }
}

/* Tablet */
@media only screen and (min-width: 401px) and (max-width: 1023px) {
  .card-produk {
    overflow: hidden;
    /* display: grid; */
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    padding-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    border: 2px solid #e5e2e2; /* Border */
    width: 180px;
    height: 280px;
    margin-bottom: 10px;
  }
  .gambar-produk {
    display: grid;
    width: 150px;
    height: 150px;
    /* padding: 10px; */
    border-radius: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .title-produk {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif; /* Font family for consistency */
    font-weight: 600;
    color: #333;
    height: 47px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    margin-left: 10px;
    font-family: "Roboto", sans-serif;
  }
  .harga-produk {
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
    color: #6a11cb;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .card-produk {
    overflow: hidden;
    /* display: grid; */
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    padding-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    border: 2px solid #e5e2e2; /* Border */
    width: 200px;
    height: 310px;
    margin-bottom: 10px;
  }
  .gambar-produk {
    display: grid;
    width: 180px;
    height: 180px;
    /* padding: 10px; */
    border-radius: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .title-produk {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif; /* Font family for consistency */
    font-weight: 600;
    color: #333;
    height: 47px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    margin-left: 10px;
    font-family: "Roboto", sans-serif;
  }
  .harga-produk {
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
    color: #6a11cb;
    font-family: "Roboto", sans-serif;
  }
}

.custom-card:hover {
  transform: translateY(-10px);
}
