.bodyContainer {
  margin-bottom: 50px;
}
.nav-container {
  /* position: fixed; */
  display: flex;
  width: 100%;
  top: 0;
  padding-left: 15px;
  padding-right: 15px;
  height: 50px;
  background-color: #673ab7;
}
.nav-detail {
  flex-wrap: nowrap;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-right: -5px;
}
.button-back {
  color: white;
  border-color: white;
  background-color: #673ab7;
  font-size: 20px;
  flex: 2;
}
.nav-title {
  color: white;
  /* display: flex;
  flex: 2; */
  margin-left: 20px;
  /* width: 100%; */
  font-size: 1.2rem;
  white-space: nowrap; /* Jangan memecah teks ke baris baru */
  overflow: hidden; /* Sembunyikan teks yang melebihi batas */
  text-overflow: ellipsis; /* Tampilkan titik-titik (...) pada teks yang dipotong */
  text-align: center; /* Mengatur teks ke tengah secara horizontal */
}
.containerContent {
  margin-top: 60px;
  margin-left: 10px;
  margin-right: 10px;
}
.cardContent {
  width: 100%;
}
.div-image {
  text-align: center;
}
.image-detail {
  padding: 10px;
  width: 300px;
  display: block;
  margin: 0 auto;
}
.placeholder-box-detail {
  padding: 10px;
  width: 230px;
  height: 230px;
  display: block;
  margin: 0 auto;
  text-align: center;
  line-height: 230px;
}
.containerTitle {
  margin-left: 10px;
}
.titleDetail {
  margin-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;
  margin-bottom: 5px;
}
.containerBarcode {
  display: flex;
  margin-top: 20px;
  border: 2px solid #673ab7; /* Border */
  border-radius: 20px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}
.satuan {
  margin-left: 10px;
  flex: 0.5;
  display: flex;
  align-items: center;
  font-weight: 500px;
  font-size: 25px;
}
.angkaBarcode {
  display: flex;
  align-items: center;
  flex: 1;
}
.barcode-info {
  /* display: flex;
  align-items: center; */
  text-align: center;
  width: 300px;

  /* flex: 1; */
}
.jumlah {
  display: flex;
  align-items: center;
  flex: 0.1;
  /* width: 50px; */
}
.print {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 10px;
}
/* .barcode-container {
  position: relative;
} */

@media print {
  .containerContent * {
    display: none;
  }
  .nav-container * {
    display: none;
  }

  .printContainer {
    left: 0;
    display: block;
    width: 57mm;
    text-align: center;
    z-index: 1000;
    position: absolute;
    top: 0;
    margin-top: 0;
  }
  .printBarcode {
    border-bottom: 1px solid #000;
    margin-bottom: 5px;
  }
  .printContainer svg {
    width: 45mm;
    height: auto;
    margin-bottom: 5px;
  }

  @page {
    margin: 0;
  }

  .product-name * {
    width: 55mm;
    font-size: 8px;
    font-weight: 800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}
