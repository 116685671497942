/* Gaya untuk Navbar */
.search-navbar {
  padding-top: 20px;
  height: 80px; /* Sesuaikan tinggi jika diperlukan */
  width: 100%;
  background-color: #673ab7; /* Warna latar belakang */
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1); /* Bayangan untuk efek mengambang */
}

/* Tambahkan ini untuk meratakan elemen di tengah secara horizontal */
.search-navbar .container {
  display: flex;
  justify-content: center;
}
.justifyContentCenter {
  display: flex;
  justify-content: center;
}

.formNavbarSearch {
  width: 80%;
  margin-bottom: 5px; /* Tambahkan margin bawah untuk jarak */
}

.searchInput {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 18px;
  height: 45px;
  width: 100%; /* Ubah menjadi 100% untuk mengambil seluruh lebar container */
  padding-left: 20px;
}
