.searchDetail {
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  margin-bottom: 70px;
  scroll-behavior: smooth;
}
.navbarSearch {
  position: fixed;
  width: 100%;
  top: 0;
}
.judulSearch {
  padding-top: 100px;
  margin-left: 10px;
  font-weight: 400;
  font-size: 20px;
  margin-top: 10px;
}
/* .searchContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 70px;
  width: 100%;
} */
@media only screen and (max-width: 400px) {
  .searchContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 70px;
    /* width: 100%; */
  }
}
@media only screen and (min-width: 401px) and (max-width: 1023px) {
  .searchContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-left: 10px;
    /* margin-right: 10px; */
    margin-bottom: 70px;
    /* width: 100%; */
  }
}
@media only screen and (min-width: 1024px) {
  .searchContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-left: 10px;
    /* margin-right: 10px; */
    margin-bottom: 70px;
    /* width: 100%; */
  }
}
.searchDetailnonProduct {
  margin: 0;
  padding: 0;
  height: 100%;
}
.judulSearchnonProduct {
  height: 100%;
}
.nonProduct {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
