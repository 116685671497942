.popup-install,
.popup-success {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.popup-install {
  color: #fff;
  background: linear-gradient(135deg, #6a11cb 70%, #a902f7 30%);
  border: none;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 40px;
  width: 340px;
  height: auto;
  text-align: center;
  font-family: Roboto, sans-serif;
  animation: 0.5s ease-out fadeIn;
}
.install-button {
  background-color: #fff;
  color: #673ab7;
  border: 2px solid #673ab7;
  padding: 20px 40px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.5em;
  transition: 0.3s;
  margin-top: 20px;
  animation: 2s infinite alternate pulse;
}
.button-link,
.popup-success {
  background-color: #6a11cb;
  text-align: center;
}
.install-button:hover {
  background-color: #673ab7;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
.popup-install h1 {
  font-family: Pacifico, cursive;
  font-size: 2.5em;
  margin-bottom: 10px;
}
.popup-install h2 {
  font-family: Roboto, sans-serif;
  font-size: 1.8em;
  margin-bottom: 20px;
}
.popup-install p {
  font-size: 1.1em;
  margin-bottom: 20px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
.popup-success {
  width: 280px;
  color: #fff;
  padding: 20px;
  box-shadow: 0 2 50px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.popup-success p {
  margin: 0;
  padding-bottom: 10px;
  font-size: 18px;
}
.button-link {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid #fff;
  width: 80%;
}
.button-link:hover {
  background-color: #8e2af9;
}
